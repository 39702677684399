<template>
  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-4">
      <card
        :title="true"
        :titleClass="'mb-4'"
        :bodyClass="'pr-0'"
        :class="{ 'h-full': total }"
      >
        <template v-slot:title>
          <h4 class="mb-0">My students</h4>
          <p class="color-primary-darkest fz-normal mb-0">
            {{ total }} students
          </p>
        </template>
        <div v-if="GET_STUDENTS.length" class="">
          <user-link
            v-for="student in GET_STUDENTS"
            :key="student.id"
            :id="student.id"
            :name="student.name"
            :withDelete="true"
            @remove="remove"
          />
        </div>
        <p class="small-text gray-color" v-else>
          No students
        </p>
      </card>
    </div>

    <div class="col-span-8">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userLink from '@/components/user/UserLink'
export default {
  name: 'my-srudents',
  components: {
    userLink,
  },
  computed: {
    ...mapGetters(['GET_STUDENTS']),
    total() {
      return this.GET_STUDENTS.length
    },
  },
  mounted: function () {
    if (!this.$store.dispatch('students_request')) {
      this.$store.dispatch('students_request')
    }
  },
  methods: {
    remove(id) {
      //метод для дальнейшего использования
      console.log(id)
    },
  },
}
</script>
